<template>
  <DetailView
    class="cart-rule-detail"
    :title="title"
    :loading="runningAction"
    @close="$emit('close', false)"
  >
    <template #actions="{ loading }">
      <v-btn
        v-if="!disabled"
        data-test-id="saveCartRuleBtn"
        :disabled="loading"
        :color="hasViolation ? 'error' : 'green'"
        :outlined="!hasChanged && !rule.isNew && !hasViolation"
        @click="saveRule"
      >
        <ViolationAlert
          v-if="hasViolation"
          :violation="violation"
          color="white"
          alignment="left"
        />
        {{ rule.isNew ? "Create" : "Save" }}
      </v-btn>
      <v-btn
        v-if="!cartRule.isNew && !disabled"
        outlined
        color="red"
        data-test-id="deleteCartRuleBtn"
        class="ml-2"
        :disabled="loading"
        @click="deleteRule"
      >
        Delete
      </v-btn>
    </template>

    <!-- eslint-disable-next-line -->
    <template #content="{ loading }">
      <v-form ref="ruleForm">
        <v-card flat>
          <v-card-title>Rule</v-card-title>
          <v-container fluid>
            <v-row v-if="!cartRule.isNew">
              <v-col>
                <v-text-field
                  :value="cartRule.id"
                  dense
                  outlined
                  disabled
                  readonly
                  hide-details
                  label="ID"
                  class="cart-rule-id"
                  data-test-id="cartRuleIdInput"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="cartRule.domainId"
                  dense
                  outlined
                  disabled
                  hide-details="auto"
                  label="Domain"
                  data-test-id="cartRuleDomainInput"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="cartRule.name"
                  dense
                  outlined
                  hide-details="auto"
                  label="Name*"
                  data-test-id="cartRuleNameInput"
                  :disabled="loading || disabled"
                  :rules="[ruleSet.required]"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="cartRule.priority"
                  dense
                  outlined
                  type="number"
                  hide-details="auto"
                  label="Priority*"
                  data-test-id="cartRulePriorityInput"
                  :disabled="loading || disabled"
                  :rules="[ruleSet.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="cartRule.validFrom"
                  dense
                  outlined
                  type="date"
                  hide-details="auto"
                  label="Valid from"
                  data-test-id="cartRuleValidFromInput"
                  :disabled="loading || disabled"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="cartRule.validUntil"
                  dense
                  outlined
                  type="date"
                  hide-details="auto"
                  label="Valid Until"
                  data-test-id="cartRuleValidUntilInput"
                  :disabled="loading || disabled"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="cartRule.description"
                  outlined
                  hide-details="auto"
                  label="Description"
                  data-test-id="cartRuleDescriptionInput"
                  :disabled="loading || disabled"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card flat class="mt-3">
          <v-card-title> Condition </v-card-title>
          <RuleExpressionEditor
            v-model="cartRule.condition"
            ref="conditionEditor"
            :types="conditionTypes"
            :key="cartRule.id"
            :disabled="loading || disabled"
            :data-test-id="
              'cart_rule_' +
              (cartRule.condition ? cartRule.condition.type : undefined)
            "
            class="ma-3"
          />
        </v-card>
        <v-card flat class="mt-3">
          <v-card-title>Grouping</v-card-title>
          <v-container v-if="cartRule.grouping" fluid>
            <v-row>
              <v-col>
                <v-select
                  v-model="cartRule.grouping.groupingCriteria"
                  attach
                  dense
                  outlined
                  hide-details="auto"
                  label="Grouping Criteria"
                  data-test-id="cartRuleGroupingCriteriaSelect"
                  :disabled="loading || disabled"
                  :items="groupingCriteria"
                  :rules="[ruleSet.required]"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="cartRule.grouping.minCount"
                  dense
                  outlined
                  type="number"
                  hide-details="auto"
                  label="Min. Count"
                  data-test-id="cartRuleGroupingMinCountInput"
                  :disabled="loading || disabled"
                  :rules="[ruleSet.required, ruleSet.smallerThanMax]"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="cartRule.grouping.maxCount"
                  dense
                  outlined
                  type="number"
                  hide-details="auto"
                  label="Max. Count"
                  data-test-id="cartRuleGroupingMaxCountInput"
                  :disabled="loading || disabled"
                  :rules="[ruleSet.required, ruleSet.greaterThanMin]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card flat class="my-3">
          <v-card-title
            :class="{
              'error--text': !hasValidActions,
            }"
          >
            Actions
          </v-card-title>
          <v-card-subtitle
            v-if="!hasValidActions"
            data-test-id="actionsAreRequired"
            class="error--text"
          >
            Actions are required
          </v-card-subtitle>
          <RuleActionListEditor
            v-model="cartRule.actions"
            :action-types="actionTypes"
            :key="cartRule.id"
            :disabled="loading || disabled"
            ref="actionListEditor"
            data-test-id="cartRuleActionList"
          />
        </v-card>
      </v-form>
    </template>
  </DetailView>
</template>
<script>
import DetailView from "../common/templates/DetailView";
import RuleExpressionEditor from "./expressions/RuleExpressionEditor";
import RuleActionListEditor from "./action/RuleActionListEditor";
import ViolationAlert from "../common/display-helpers/ViolationAlert";
export default {
  components: {
    DetailView,
    RuleExpressionEditor,
    RuleActionListEditor,
    ViolationAlert,
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },

    conditionTypes: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    actionTypes: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      cartRule: this.$cloneObject(this.rule) ?? {},
      runningAction: false,
      violation: null,
      savedRule: null,
      hasChanged: false,
    };
  },

  mounted() {
    if (!this.cartRule.grouping) {
      //set default grouping
      this.$set(this.cartRule, "grouping", {
        groupingCriteria: "VALID_FROM_DATE",
        minCount: 1,
        maxCount: 1,
      });
    }
    this.$nextTick(() => {
      this.savedRule = this.$cloneObject(this.cartRule);
    });
  },

  watch: {
    rule: {
      handler: function (rule) {
        this.cartRule = this.$cloneObject(rule);
      },
      deep: true,
    },

    cartRule: {
      handler: function (rule) {
        this.hasChanged = !this.$isEqual(rule, this.savedRule);
      },
      deep: true,
    },

    savedRule: {
      handler: function (savedRule) {
        this.hasChanged = !this.$isEqual(this.cartRule, savedRule);
      },
      deep: true,
    },

    hasChanged(hasChanged) {
      this.$set(
        this.$route.params,
        "unsavedChanges",
        this.cartRule?.isNew ?? hasChanged
      );
    },
  },

  methods: {
    async saveRule() {
      try {
        if (!this.validate()) return;
        this.runningAction = true;
        const successMsg =
          "Cart rule " +
          this.cartRule.id +
          (this.cartRule.isNew ? " created" : " updated");
        const res = await this.$store.$coreApi.coreCartRuleApi.upsertRule(
          this.selectedDomain,
          this.cartRule,
          successMsg,
          {
            returnErrors: true,
          }
        );

        if (!res?.ok) {
          const violation = await res.json();
          this.violation = violation;
          this.$store.dispatch(
            "setError",
            "Saving " + this.cartRule.id + " failed!"
          );
          return;
        }

        this.violation = null;
        this.$delete(this.cartRule, "isNew");
        this.savedRule = this.$cloneObject(this.cartRule);
        this.$nextTick(() => this.$emit("reload"));
      } finally {
        this.runningAction = false;
      }
    },

    async deleteRule() {
      try {
        const id = this.cartRule.id;
        const confirmed = await this.$confirm(
          "Delete cart rule?",
          "Do you really want to delete the cart rule " +
            id +
            "? This action is irreversible."
        );
        if (confirmed) {
          this.runningAction = true;
          const res = await this.$store.$coreApi.coreCartRuleApi.deleteRule(
            this.selectedDomain,
            id
          );

          if (!res?.ok) return;
          this.hasChanged = false;
          this.$nextTick(() => this.$emit("close", true));
        }
      } finally {
        this.runningAction = false;
      }
    },

    validate() {
      const form = this.$refs.ruleForm;
      if (!this.$validateVForm(form)) {
        this.violation = {
          message: "At least one input is invalid, please check",
        };
        return false;
      }

      if (!this.hasValidActions) {
        const el = this.$refs.actionListEditor.$el;
        this.$vuetify.goTo(el);
        this.violation = {
          message: "Cart rule actions are not valid, please check",
        };
        return false;
      }

      this.violation = null;

      return true;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    title() {
      return this.rule?.isNew ? "New cart rule" : this.rule.name;
    },

    groupingCriteria() {
      return [
        { text: "NONE", value: "NONE" },
        { text: "VALID_FROM_DATE", value: "VALID_FROM_DATE" },
      ];
    },

    ruleSet() {
      const min = Number(this.cartRule.grouping?.minCount);
      const max = Number(this.cartRule.grouping?.maxCount);
      //possible validation rules
      return {
        required: (val) => {
          if (val === undefined || val === "" || val === null)
            return "Value is required";
          return true;
        },

        smallerThanMax: (val) => {
          if (!max) return true;
          const number = Number(val);
          const smallerThanMax =
            number === null || number === undefined || number <= max;
          return smallerThanMax || "Must be smaller than or equal to " + max;
        },

        greaterThanMin: (val) => {
          if (!min) return true;
          const number = Number(val);
          const greaterThanMin =
            number === null || number === undefined || number >= min;
          return greaterThanMin || "Must be greater than or equal to " + min;
        },
      };
    },

    hasViolation() {
      return !!this.violation;
    },

    hasValidActions() {
      const actions = this.cartRule?.actions;
      return actions && actions?.length > 0;
    },
  },
};
</script>
<style scoped>
.cart-rule-detail::v-deep .action-list-editor {
  margin: 12px 12px 150px 12px;
}
</style>