<template>
  <div
    :class="{
      'condition-editor': true,
      'is-compound': isCompound,
    }"
    :data-test-id="dataTestId"
  >
    <div
      v-if="isCompound || !hasType"
      :key="isCompound"
      class="condition-editor-header"
    >
      <v-select
        :value="type"
        attach
        dense
        outlined
        return-object
        item-text="name"
        class="condition-type-select"
        hide-details="auto"
        placeholder="Select type..."
        :data-test-id="dataTestId + '_type_select'"
        :items="sortedTypes"
        :disabled="disabled"
        :rules="getInputRules({ required: true })"
        @change="changeType"
      />
      <v-btn
        v-if="!disabled && isCompound"
        icon
        color="primary"
        class="ml-3"
        :data-test-id="dataTestId + '_add_condition_btn'"
        @click="addCondition"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!disabled && (type || parent)"
        icon
        color="red"
        :data-test-id="dataTestId + '_remove_btn'"
        @click="handleDelete"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <div
      v-if="hasType && hasContent"
      :class="{
        'condition-editor-content': true,
        'is-invalid': !isValid,
      }"
    >
      <v-form ref="conditionSentenceForm">
        <div v-if="isCompound" :key="rebuildRules" class="compound-rules">
          <RuleExpressionEditor
            v-for="(condition, index) in model.conditions"
            v-model="model.conditions[index]"
            :key="index + condition.type"
            :types="types"
            :parent="model"
            :disabled="disabled"
            :data-test-id="
              dataTestId + '_condition_' + index + '_' + condition.type
            "
            @delete="removeCondition(index)"
          />
          <v-btn
            v-if="false && !disabled"
            text
            color="primary"
            data-test-id="addConditionBtn"
            class="condition-editor-add-btn"
            @click="addCondition"
          >
            <v-icon>mdi-plus</v-icon> Add Condition
          </v-btn>
        </div>
        <div v-else class="d-flex flex-row">
          <div class="condition-editor-sentence" ref="conditionSentence">
            <div
              v-for="(part, index) in sentenceParts"
              :key="index"
              :class="{
                'sentence-part': true,
                [part.type]: true,
              }"
            >
              <div v-if="part.type === 'text'" class="sentence-text">
                {{ part.value }}
              </div>
              <GrowingTextField
                v-if="part.type === 'string' && !part.items"
                v-model="model.configuration[part.id]"
                hide-details="auto"
                :placeholder="part.name"
                :rules="getInputRules(part)"
                :disabled="disabled"
                :data-test-id="dataTestId + '_input_' + part.id"
              />
              <GrowingTextField
                v-else-if="part.type === 'number' || part.type === 'integer'"
                v-model="model.configuration[part.id]"
                type="number"
                hide-details="auto"
                :placeholder="part.name"
                :rules="getInputRules(part)"
                :disabled="disabled"
                :data-test-id="dataTestId + '_input_' + part.id"
              />
              <v-select
                v-else-if="part.type === 'string' && part.items"
                v-model="model.configuration[part.id]"
                attach
                dense
                outlined
                hide-details="auto"
                :placeholder="part.name"
                :items="part.items"
                :rules="getInputRules(part)"
                :disabled="disabled"
                :data-test-id="dataTestId + '_select_' + part.id"
              />
              <v-select
                v-else-if="part.type === 'boolean'"
                v-model="model.configuration[part.id]"
                attach
                dense
                outlined
                hide-details="auto"
                :placeholder="part.name"
                :items="booleanOptions"
                :rules="getInputRules(part)"
                :disabled="disabled"
                :data-test-id="dataTestId + '_select_' + part.id"
              />
              <v-select
                v-else-if="part.type === 'array'"
                v-model="model.configuration[part.id]"
                attach
                dense
                outlined
                multiple
                item-text="label"
                hide-details="auto"
                :placeholder="part.name"
                :items="part.items"
                :rules="getInputRules(part)"
                :disabled="disabled"
                :data-test-id="dataTestId + '_select_' + part.id"
              />
            </div>
          </div>
          <v-spacer />
          <div class="conditon-editor-remove-btn">
            <v-btn
              v-if="!disabled"
              icon
              color="red"
              :data-test-id="dataTestId + '_remove_btn'"
              @click="handleDelete"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>
<script>
import mixin from "../../../mixins/data-editor-mixin";
export default {
  mixins: [mixin],

  components: {
    RuleExpressionEditor: () => import("./RuleExpressionEditor"),
    GrowingTextField: () => import("../../common/inputs/GrowingTextField"),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    types: {
      type: Array,
      required: true,
    },

    parent: {
      type: [Object, Array],
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    dataTestId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      model: this.value,
      type: null,
      rebuildRules: 0,
      isInvalid: false,
    };
  },

  mounted() {
    const typeId = this.model?.type;
    if (typeId) {
      this.type = this.types.find(({ id }) => typeId === id);
    }
    this.$nextTick(() => this.validate());
  },

  watch: {
    model: {
      handler: function (model) {
        const typeId = this.model?.type;
        if (typeId !== this.type?.id) {
          this.type = this.types.find(({ id }) => typeId === id);
        }
        this.validate();
        this.$emit("input", model);
      },
      deep: true,
    },

    type(type) {
      const id = type?.id;
      this.$set(this.model, "type", id);
      if (!this.isCompound && this.model?.conditions?.length > 0) {
        //type switch from compound to primitive type, so remove
        //any conditions
        this.$set(this.model, "conditions", []);
      }

      if (!this.model.configuration) {
        this.$set(this.model, "configuration", {});
      }
    },
  },

  methods: {
    changeType(type) {
      this.type = type;
      this.$nextTick(() => {
        if (this.isCompound) return;
        //Focus the first inpput of the condition sentence
        const element = this.$refs?.conditionSentence;
        const input = element?.getElementsByTagName("input")?.[0];
        if (input) input.focus();
      });
    },

    addCondition() {
      let conditions = this.model.conditions ?? [];
      conditions.push({
        type: null,
        configuration: {},
        conditions: [],
      });

      this.$set(this.model, "conditions", conditions);
    },

    handleDelete() {
      if (this.hasParent) {
        this.$emit("delete");
        return;
      }

      //the condition is the root condition,
      //so just reset it
      this.model = {
        type: null,
        configuration: {},
        conditions: [],
      };
    },

    removeCondition(index) {
      this.model.conditions.splice(index, 1);
      this.rebuildRules++;
    },

    getInputRules(part) {
      let rules = [];
      const schema = part?.schema;
      const type = schema?.type;
      if (part.required)
        rules.push((val) => {
          return !!val || val === 0 || "Value is required";
        });
      if (type === "array") {
        if (schema?.minItems) rules.push(this.ruleSet.minItems(schema));
        if (schema?.maxItems) rules.push(this.ruleSet.maxItems(schema));
      }
      if (type === "number" || type === "integer") {
        if (type === "integer") rules.push(this.ruleSet.integer);
        if (schema?.multipleOf) rules.push(this.ruleSet.multipleOf(schema));
        if (schema?.minimum) rules.push(this.ruleSet.minimum(schema));
        if (schema?.exclusiveMinimum)
          rules.push(this.ruleSet.exclusiveMinimum(schema));
        if (schema?.maximum) rules.push(this.ruleSet.maximum(schema));
        if (schema?.exclusiveMaximum)
          rules.push(this.ruleSet.exclusiveMaximum(schema));
      }

      if (type === "string") {
        if (schema?.minLength) rules.push(this.ruleSet.minLength(schema));
        if (schema?.maxLength) rules.push(this.ruleSet.maxLength(schema));
        if (schema?.pattern) rules.push(this.ruleSet.pattern(schema));
      }

      return rules;
    },

    validate() {
      const form = this.$refs?.conditionSentenceForm;
      this.isValid = form?.validate?.() ?? true;
    },
  },

  computed: {
    hasType() {
      return !!this.model?.type;
    },

    hasParent() {
      return !!this.parent;
    },

    isCompound() {
      if (!this.hasType) return false;
      const type = this.types.find(({ id }) => this.model?.type === id);
      return !!type?.hasSubConditions;
    },

    sortedTypes() {
      const types = this.$cloneObject(this.types);
      return types.sort((type1, type2) => {
        const name1 = type1.name;
        const name2 = type2.name;
        return name1.localeCompare(name2);
      });
    },

    sentenceParts() {
      const type = this.type;
      if (!type || this.isCompound) return [];
      if (!type.schema || !type.schema?.properties) {
        return [{ type: "text", value: type.ui }];
      }
      const regex = new RegExp("[{}]");
      //split the sentence at the placeholders
      const parts = type.ui.replaceAll("{", "{VAR:").split(regex);
      return parts.map((part) => {
        if (part.startsWith("VAR:")) {
          //part is a placeholder for a variable
          //so determine its type and build the model for the correct input
          const id = part.replace("VAR:", "");
          const schema = type?.schema?.properties?.[id];
          const required = type?.schema?.required?.includes(id) ?? false;
          const partType = schema?.type;
          const name = id + (required ? "*" : "");

          let partModel = {
            type: schema?.type,
            id,
            name,
            schema,
            required,
          };

          if (partType === "array") {
            //multi select, must be string enum schema
            //as item schema
            partModel.items = schema.items?.enumOptions ?? [];
          } else if (partType === "string" && schema?.enumOptions) {
            //single select
            partModel.items = schema.enumOptions;
          }
          return partModel;
        } else {
          //part is plain text
          return {
            type: "text",
            value: part,
          };
        }
      });
    },

    hasContent() {
      return (
        this.model?.conditions?.length > 0 || this.sentenceParts?.length > 0
      );
    },

    booleanOptions() {
      return [
        { value: true, text: "True" },
        { value: false, text: "False" },
      ];
    },
  },
};
</script>
<style scoped>
.condition-editor {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  border: 1px solid #eeeeee;
  padding: 8px;
}

.condition-editor > .condition-editor-header {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
}

.condition-editor.is-compound
  > .condition-editor-header
  + .condition-editor-content
  > .v-form
  > .compound-rules {
  margin-top: 8px;
}

.condition-editor.is-compound > .condition-editor-content {
  padding-left: 16px;
}

.condition-editor > .condition-editor-header > .condition-type-select {
  max-width: 300px;
}

.condition-editor > .condition-editor-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.conditon-editor-remove-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.condition-editor
  > .condition-editor-content
  > .v-form
  > .compound-rules
  > .condition-editor-add-btn {
  width: 100%;
  height: 48px;
}

.condition-editor
  > .condition-editor-content
  > .v-form
  > div
  > .condition-editor-sentence {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-wrap;
  flex-wrap: wrap;
}

.condition-editor
  > .condition-editor-content
  > .v-form
  > div
  > .condition-editor-sentence
  .sentence-text {
  white-space: nowrap;
}

.condition-editor
  > .condition-editor-content
  > .v-form
  > div
  > .condition-editor-sentence
  > .sentence-part {
  margin: 4px;
  display: flex;
  align-items: flex-start;
  height: 40px;
}

.condition-editor
  > .condition-editor-content.is-invalid
  > .v-form
  > div
  > .condition-editor-sentence
  > .sentence-part {
  height: 64px;
}

.condition-editor
  > .condition-editor-content
  > .v-form
  > div
  > .condition-editor-sentence
  > .sentence-part.text {
  padding-top: 8px;
}

.condition-editor > .condition-editor-content > .v-form > .compound-rules {
  box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 75%),
    inset 0px -4px 8px -5px rgb(50 50 50 / 75%);
}
</style>