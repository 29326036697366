<template>
  <MasterDetail
    class="cart-rule-overview"
    data-test-id="cartRuleOverview"
    :detail-open="!!selectedRule"
    @table:resize="tableWidth = $event"
  >
    <template #toolbar>
      <v-toolbar>
        <v-toolbar-title>Cart Rules</v-toolbar-title>
        <v-divider vertical class="mx-3" />
        <v-spacer />
        <v-switch
          v-model="showParentRules"
          label="Include rules of parents"
          hide-details
          data-test-id="includeParentRulesSelect"
        />
        <v-divider inset vertical class="mx-3" />
        <v-btn
          color="primary"
          data-test-id="newCartRuleBtn"
          title="Create a new cart rule"
          :disabled="runningAction || isModeCreate"
          :fab="showMinified"
          :small="showMinified"
          @click="openRuleDetail()"
        >
          <v-icon v-if="showMinified">mdi-plus</v-icon>
          <div v-else>New Cart Rule</div>
        </v-btn>
      </v-toolbar>
    </template>

    <template #table>
      <v-data-table
        class="cart-rule-table"
        dense
        hide-default-footer
        fixed-header
        multi-sort
        single-select
        item-key="id"
        :item-class="getItemClass"
        :height="tableHeight"
        :items-per-page="-1"
        :items="displayedCartRules"
        :headers="headers"
        :options.sync="options"
        :loading="runningAction"
        @click:row="openRuleDetail"
      >
        <!-- eslint-disable-next-line -->
        <template #item.validFrom="{ item }">
          {{
            item.validFrom
              ? $getLocalizedDate(item.validFrom, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              : "-"
          }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.validUntil="{ item }">
          {{
            item.validUntil
              ? $getLocalizedDate(item.validUntil, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              : "-"
          }}
        </template>
      </v-data-table>
    </template>

    <template #detail>
      <CartRuleDetail
        v-if="selectedRule"
        data-test-id="cartRuleDetail"
        :key="selectedRule.id + reloadDetailKey"
        :rule="selectedRule"
        :action-types="actionTypes"
        :condition-types="conditionTypes"
        :disabled="detailDisabled"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="closeRuleDetail"
        @reload="init(true)"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "../common/templates/MasterDetail";
import CartRuleDetail from "./CartRuleDetail";
import mainOverviewMixin from "../../mixins/main-overview-mixin";

export default {
  mixins: [mainOverviewMixin],

  components: {
    MasterDetail,
    CartRuleDetail,
  },

  data() {
    return {
      runningAction: false,
      rules: [],
      selectedRule: null,
      createRule: false,
      actionTypes: [],
      conditionTypes: [],
      options: {
        sortBy: ["priority", "name"],
        sortDesc: [false, false],
      },
      reloadDetailKey: 0,
      showParentRules: null,
      tableWidth: 0,
    };
  },

  mounted() {
    this.init();
  },

  watch: {
    options(options) {
      let query = Object.assign({}, this.$route.query);
      const sortBy = options.sortBy;
      const sortDesc = options.sortDesc;
      let sort = "";

      //build sort url parameter value
      if (Array.isArray(sortBy)) {
        for (let i = 0; i < sortBy.length; i++) {
          const sortQuery = (sortDesc[i] ? "-" : "") + sortBy[i];
          if (!sort) sort = sortQuery;
          else sort += "," + sortQuery;
        }
      }

      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);

      this.$router.push({
        ...this.$route,
        query,
      });
    },

    showParentRules(showParentRules) {
      let query = Object.assign({}, this.$route.query);
      this.$set(query, "parent-rules", showParentRules);
      this.$router.push({
        ...this.$route,
        query,
      });
    },
  },

  methods: {
    async init(updateRules) {
      try {
        this.runningAction = true;
        if (updateRules) {
          await this.getRules();
        } else {
          const routeQuery = this.$route.query;
          let sort = routeQuery.sort;
          let showParentRules = routeQuery["parent-rules"];

          if (sort) {
            const sortBy = [];
            const sortDesc = [];
            const sortQuery = sort.split(",");

            const addToSorted = (sortString) => {
              //this function parses the given string and adds the
              //sorting information to the respective arrays
              const isDesc = sortString.startsWith("-");
              const property = isDesc ? sortString.substring(1) : sortString;
              const isSortableField = this.headers.some(
                (header) =>
                  header.value === property && header.sortable !== false
              );
              if (isSortableField) {
                sortBy.push(property);
                sortDesc.push(isDesc);
              }
            };

            if (Array.isArray(sortQuery))
              sortQuery.forEach((part) => addToSorted(part));
            else addToSorted(sortQuery);
            this.options = Object.assign(this.options, { sortBy, sortDesc });
          }

          this.showParentRules = showParentRules !== "false";

          await Promise.all([
            this.getRules(),
            this.getActionTypes(),
            this.getConditionTypes(),
          ]);
        }

        if (this.$route.matched.some(({ name }) => name === "cartRuleDetail")) {
          const ruleId = this.$route?.params?.rule;
          const rule = this.rules.find(({ id }) => ruleId === id);
          if (!rule) {
            this.$store.dispatch(
              "setError",
              "Cannot find cart rule with id " + ruleId
            );
            this.closeRuleDetail();
            return;
          }
          if (this.selectedRule?.id === ruleId) {
            this.selectedRule = rule;
          } else {
            this.openRuleDetail(rule);
          }
        } else {
          this.selectedRule = null;
        }
      } finally {
        this.reloadDetailKey++;
        this.runningAction = false;
      }
    },

    async getRules() {
      const rules = await this.$store.$coreApi.coreCartRuleApi.getRules(
        this.selectedDomain
      );

      this.rules = rules;
    },

    async getActionTypes() {
      const actionTypes =
        await this.$store.$coreApi.coreCartRuleApi.getActionTypes(
          this.selectedDomain
        );

      this.actionTypes = actionTypes;
    },

    async getConditionTypes() {
      const conditionTypes =
        await this.$store.$coreApi.coreCartRuleApi.getConditionTypes(
          this.selectedDomain
        );

      this.conditionTypes = conditionTypes;
    },

    async openRuleDetail(rule = {}) {
      if (!rule.id) {
        this.$set(rule, "id", this.$uuid.v4());
        this.$set(rule, "isNew", true);
      }

      await this.$router.push({
        name: "cartRuleDetail",
        params: {
          rule: rule.id,
        },
        query: this.$route.query,
      });

      this.selectedRule = rule;
    },

    async closeRuleDetail(reload) {
      await this.$router.push({
        name: "cartRules",
        query: this.$route.query,
      });

      this.selectedRule = false;
      if (reload) this.init(true);
    },

    getItemClass(item) {
      let itemClass = "cart-rule-item";
      if (item.id === this.selectedRule?.id) itemClass += " selected";
      if (item.domainId !== this.selectedDomain) itemClass += " inherited";
      return itemClass;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    tableHeight() {
      return this.getPageHeight() - 80;
    },

    headers() {
      return [
        {
          text: "Priority",
          value: "priority",
          cellClass: "cart-rule-priority",
        },
        { text: "Name", value: "name", cellClass: "cart-rule-name" },
        { text: "Domain", value: "domainId", cellClass: "cart-rule-domain" },
        {
          text: "Valid from",
          value: "validFrom",
          cellClass: "cart-rule-valid-from",
        },
        {
          text: "Valid until",
          value: "validUntil",
          cellClass: "cart-rule-valid-until",
        },
      ];
    },

    displayedCartRules() {
      const rules = this.rules ?? [];
      if (!this.showParentRules) {
        return rules.filter(({ domainId }) => domainId === this.selectedDomain);
      }
      return rules;
    },

    detailDisabled() {
      const rule = this.selectedRule;
      return !rule?.isNew && this.selectedDomain !== rule?.domainId;
    },

    showMinified() {
      return (
        this.getPageWidth() <= 900 ||
        (this.selectedRule && this.tableWidth <= 900)
      );
    },

    isModeCreate() {
      return this.selectedRule?.isNew;
    },
  },
};
</script>

<style scoped>
.cart-rule-overview .cart-rule-table::v-deep .cart-rule-item.inherited {
  opacity: 0.5;
}
</style>