<template>
  <div class="action-list-editor">
    <div v-if="actions.length > 0">
      <RuleExpressionEditor
        v-for="(action, index) in actions"
        v-model="actions[index]"
        class="my-1"
        :types="actionTypes"
        :parent="actions"
        :key="index + action.type"
        :disabled="disabled"
        :data-test-id="'cart_rule_action_' + index"
        @delete="removeAction(index)"
      />
    </div>
    <v-btn
      v-if="!disabled"
      text
      color="primary"
      class="action-list-editor-add-btn"
      data-test-id="addCartRuleActionBtn"
      @click="addAction"
    >
      <v-icon>mdi-plus</v-icon> Add Action
    </v-btn>
  </div>
</template>

<script>
import RuleExpressionEditor from "../expressions/RuleExpressionEditor";

export default {
  components: {
    RuleExpressionEditor,
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    actionTypes: {
      type: Array,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      actions: this.$cloneObject(this.value),
    };
  },

  watch: {
    value: {
      handler: function (value) {
        this.actions = value;
      },
      deep: true,
    },

    actions: {
      handler: function (actions) {
        this.$emit("input", actions);
      },
      deep: true,
    },
  },

  methods: {
    removeAction(index) {
      this.actions.splice(index, 1);
    },

    addAction() {
      this.actions.push({
        type: null,
        configuration: {},
      });
    },
  },
};
</script>

<style scoped>
.action-list-editor {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.action-list-editor > .action-list-editor-add-btn {
  width: 100%;
  height: 48px;
}

.action-list-editor::v-deep
  .condition-editor
  > .condition-editor-content
  > div
  > .condition-editor-sentence
  > .sentence-part {
  height: 64px;
}
</style>